import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  InputLabel,
  Breadcrumbs,
  MenuItem,
  Typography,
  Avatar,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik, FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import adminService from '../../services/adminService';
import { consoleError, getImageUrl } from '../../utils/helper';
import * as yup from 'yup';
import { TextField, Switch } from 'formik-material-ui';
import FormPreviewDev from '../../components/common/FormPreviewDev';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useMatch, useNavigate, Link } from 'react-router-dom';
import CustomFileUpload from '../../components/CustomFileUpload';
import CustomFilesUpload from '../../components/CustomFilesUpload';
import CustomFileUploadServer from '../../components/CustomFileUploadServer';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import api from 'src/services/api';
// import FileUploadServer from '../../components/FileUploadServer';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropDown: {
    height: 400,
  },
}));
function EditProduct() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [id, setId] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);

  const match = useMatch('/products/:id/edit');
  useEffect(() => {
    if (match && match.params && match.params.id) {
      setId(match.params.id);
      setUpdate(true);
    }
  }, [match]);

  const products = useSelector((state) => state.admin.products);
  const productData = products.find((Item) => Item._id === id);
  console.log(productData);
  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      adminService.getProducts(dispatch);
    } catch (error) {
      console.error(error);
    }
  };

  const addSiteSettingchema = yup.object().shape({
    category: yup
      .string()
      .required('category is required.')
      .min(2, 'category has min 2 chars.')
      .max(50, 'category has max 50 chars.'),
  });
  const updateSiteSettingchema = yup.object().shape({
    category: yup
      .string()
      .required('category is required.')
      .min(2, 'category has min 2 chars.')
      .max(50, 'category has max 50 chars.'),
  });

  const productCategories = useSelector((state) => state.admin.productcategory);
  useEffect(() => {
    async function getProductCategory() {
      try {
        adminService.getProductCategory(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getProductCategory();
  }, []);
  const [fileUrl, setFileUrl] = useState([]);

  const handleChangeFile = (event, values) => {
    setFileUrl([]);
    const images = [];
    for (let i = 0; i < event.target.files.length; i++) {
      images.push(URL.createObjectURL(event.target.files[i]));
    }
    setFileUrl({
      files: event.target.files,
      fileUrl: images,
    });

    values.productImage = {
      files: event.target.files,
      fileUrl: images,
    };

    console.log(values);
  };
  const [productImage, setProductImage] = useState([]);
  const uploadImageHandler = async (e, values, index) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    try {
      const res = await api.post(
        `/api/v1/admin/productupload/images`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      const image_New_Name = res.data;

      values.productImage[index].name = image_New_Name;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Page
      className={classes.root}
      title={update ? 'Update product' : 'Add product'}
    >
      <Box
        display="flex"
        flexdirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h2">
              {update ? 'Update product' : 'Add product'}
            </Typography>
            <Box ml={2}>
              {/* <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate('/product/add');
                }}
              >
                Add New product
              </Button> */}
            </Box>
          </Box>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Link color="grey" to="/products">
                Product
              </Link>
              <Typography color="textPrimary">
                {update ? 'Update' : 'Add'}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Formik
            enableReinitialize={true}
            initialValues={
              update
                ? {
                    ...productData,
                    category:
                      productData && productData.category
                        ? productData.category._id
                        : '',
                    currentProductImage:
                      productData &&
                      productData.productImage &&
                      productData.productImage.length > 0
                        ? productData.productImage[0].path
                        : '',
                  }
                : {
                    productType: '',
                    colors: '',
                    modelNo: '',
                    upper: '',
                    vampLining: '',
                    quarterLining: '',
                    outsole: '',
                    sizeRange: '',
                    safetyShoesStandard: '',
                    remarks: '',
                    category: '',
                    productImage: [
                      {
                        name: '',
                        color: '',
                      },
                    ],
                    // productImage1: '',
                    // productImage2: '',
                    // productImage3: '',

                    leadTime: '',
                  }
            }
            validationSchema={
              update ? updateSiteSettingchema : addSiteSettingchema
            }
            onSubmit={async (values, { resetForm }) => {
              setErrMsg('');
              setSuccessMsg('');
              const {
                _id,
                productType,
                colors,
                modelNo,
                upper,
                vampLining,
                quarterLining,
                outsole,
                sizeRange,
                safetyShoesStandard,
                remarks,
                category,
                productImage,
                // productImage1,
                // productImage2,
                // productImage3,
                leadTime,
              } = values;
              const data = {
                productType,
                colors,
                modelNo,
                upper,
                vampLining,
                quarterLining,
                outsole,
                sizeRange,
                safetyShoesStandard,
                remarks,
                category,
                productImage,
                leadTime,
              };
              if (update) {
                try {
                  let res = await adminService.updateProduct(
                    _id,
                    data,
                    dispatch,
                  );
                  if (res && res.ok) {
                    setSuccessMsg(
                      res.message || 'Product updated successfully.',
                    );
                    setTimeout(() => {
                      window.location.reload();
                    }, 500);
                  }
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message || 'Error updating Product. Please try again',
                  );
                }
              } else {
                try {
                  console.log(data);
                  let res = await adminService.addProduct(data, dispatch);
                  if (res && res.ok) {
                    setSuccessMsg(res.message || 'Product added successfully.');
                    resetForm({});
                  }
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message || 'Error adding Product. Please try again.',
                  );
                }
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                {console.log('images', productImage)}
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      name="category"
                      type="text"
                      label="Category"
                      required
                      select={true}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setFieldValue(name, value);
                      }}
                      variant="outlined"
                      fullWidth
                    >
                      {productCategories.map((data, index) => {
                        return (
                          <MenuItem key={index} value={data._id}>
                            {data.category}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Field
                      component={TextField}
                      name="productType"
                      type="text"
                      label="Product Type"
                      required
                      select={true}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setFieldValue(name, value);
                      }}
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem value={'White Safety Shoes'}>
                        White Safety Shoes
                      </MenuItem>
                      <MenuItem value={'Stylish Safety Shoes'}>
                        Stylish Safety Shoes
                      </MenuItem>
                      <MenuItem value={'Basic Safety Shoes'}>
                        Basic Safety Shoes
                      </MenuItem>
                      <MenuItem value={'Safety Boots'}>Safety Boots</MenuItem>
                    </Field>
                  </Grid> */}

                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Model"
                      margin="normal"
                      size="medium"
                      name="modelNo"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Upper"
                      margin="normal"
                      size="medium"
                      name="upper"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Vamp Lining"
                      margin="normal"
                      size="medium"
                      name="vampLining"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Quarter Lining"
                      margin="normal"
                      size="medium"
                      name="quarterLining"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Outsole"
                      margin="normal"
                      size="medium"
                      name="outsole"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Size Range"
                      margin="normal"
                      size="medium"
                      name="sizeRange"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Lead Time"
                      margin="normal"
                      size="medium"
                      name="leadTime"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Safety Shoes Standard"
                      margin="normal"
                      size="medium"
                      name="safetyShoesStandard"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Remarks"
                      margin="normal"
                      size="medium"
                      name="remarks"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Colors"
                      margin="normal"
                      size="medium"
                      name="colors"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid> */}

                  <Grid item xs={12}>
                    <Box mb={2}>
                      <Typography variant="h6">Product Images</Typography>
                    </Box>
                    <FieldArray name="productImage">
                      {({ remove, push }) => (
                        <Box>
                          {values.productImage &&
                          values.productImage.length > 0 ? (
                            values.productImage.map((productImage, index) => (
                              <Grid container key={index} spacing={2}>
                                <Grid item xs={12} md={12}>
                                  <input
                                    type="file"
                                    name={`productImage.${index}.name`}
                                    onChange={(e) =>
                                      uploadImageHandler(e, values, index)
                                    }
                                    accept="image/*"
                                  />
                                </Grid>
                                {values.productImage[index].name ? (
                                  <img
                                    src={`${config.apiUrl}${config.imageUrlLocal}/products/${values.productImage[index].name}`}
                                    alt="Service"
                                    style={{ width: '200px', height: '200px' }}
                                  />
                                ) : (
                                  ''
                                )}

                                <Grid item xs={5} md={4}>
                                  <Field
                                    component={TextField}
                                    label="Color code"
                                    size="medium"
                                    name={`productImage.${index}.color`}
                                    fullWidth
                                    variant="outlined"
                                  />
                                </Grid>

                                <Grid
                                  xs={2}
                                  md={4}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() =>
                                      values.productImage.length === 1
                                        ? remove(index) &&
                                          push({ name: '', color: '' })
                                        : remove(index)
                                    }
                                    style={{
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                    }}
                                  >
                                    <RemoveIcon />
                                    Remove
                                  </Button>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() =>
                                      push({
                                        name: '',
                                        color: '',
                                      })
                                    }
                                  >
                                    <AddIcon />
                                    Add Product Image
                                  </Button>
                                </Grid>
                              </Grid>
                            ))
                          ) : (
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={12}>
                                <input
                                  type="file"
                                  name={`productImage.${
                                    values.productImage &&
                                    values.productImage.length
                                  }.name`}
                                  onChange={(e) =>
                                    uploadImageHandler(
                                      e,
                                      values,
                                      values.productImage.length,
                                    )
                                  }
                                  accept="image/*"
                                />
                              </Grid>
                              <Grid item xs={5} md={4}>
                                <Field
                                  component={TextField}
                                  label="Color name"
                                  size="medium"
                                  name={`productImage.${
                                    values.images && values.images.length
                                  }.color`}
                                  fullWidth
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid
                                xs={2}
                                md={4}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() =>
                                    remove(values.productImage.length)
                                  }
                                  style={{
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                  }}
                                >
                                  <RemoveIcon />
                                  Remove
                                </Button>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() =>
                                    push({
                                      name: '',
                                      color: '',
                                    })
                                  }
                                >
                                  <AddIcon />
                                  Add Product Image
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </Box>
                      )}
                    </FieldArray>
                  </Grid>
                </Grid>
                <Box mt={1} mb={1}>
                  {errMsg && (
                    <Alert severity="error" onClose={() => setErrMsg('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errMsg}
                    </Alert>
                  )}
                  {successMsg && (
                    <Alert severity="success" onClose={() => setSuccessMsg('')}>
                      <AlertTitle>Success</AlertTitle>
                      {successMsg}
                    </Alert>
                  )}
                </Box>
                {(isSubmitting || uploadingImage) && <LinearProgress />}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting || uploadingImage}
                    fullWidth
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    {update ? 'Update Product' : 'Add Product'}
                  </Button>
                </Box>
                <FormPreviewDev data={values} />
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
}

export default EditProduct;
