import api from './api';

import { fetchCategories } from 'src/store/actions/categoryActions';

const categoryService = {
  fetchAllCategory: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/categories');
      if (res && res.ok) {
        dispatch(fetchCategories(res.data.categories));
        return Promise.resolve(res);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default categoryService;
