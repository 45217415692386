import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import UserListView from 'src/views/user/UserListView';

import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';

import SettingsView from 'src/views/settings/SettingsView';
import { isLoggedIn } from './utils/helper';
import ProfileListView from './views/profile';
import ProductListView from './views/product';

import ProductCategoryView from './views/productCategory';
import EditProductCategory from './views/productCategory/EditProductCategory';

import ProductsView from './views/products';
import Product from './views/products/Product';
import EditProduct from './views/products/EditProduct';

import IntroductionView from './views/introduction';
import Introduction from './views/introduction/Introduction';
import EditIntroduction from './views/introduction/EditIntroduction';

import WhyUsView from './views/whyus';
import Whyus from './views/whyus/Whyus';
import EditWhyUs from './views/whyus/EditWhyUs';

import SiteSettingView from './views/siteSetting';
import SiteSetting from './views/siteSetting/SiteSetting';
import EditSiteSetting from './views/siteSetting/EditSiteSetting';

import ProfileBannerView from './views/profileBanner';
import ProfileBanner from './views/profileBanner/ProfileBanner';
import EditProfileBanner from './views/profileBanner/EditProfileBanner';

import TimelineView from './views/timeline';
import Timeline from './views/timeline/Timeline';
import EditTimeline from './views/timeline/EditTimeline';

import CertificateView from './views/certificate';
import Certificate from './views/certificate/Certificate';
import EditCertificate from './views/certificate/EditCertificate';

import ContactView from './views/contact/ContactView';
import Contact from './views/contact/ContactView/Contact';

import Error500 from './views/errors/Error500';

export const getRoutes = (pathname) => {
  const routes = [
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: 'login',
          element: isLoggedIn() ? <Navigate to="/" /> : <LoginView />,
        },
        { path: '404', element: <NotFoundView /> },
        { path: '500', element: <Error500 /> },
        {
          path: '/',
          element: isLoggedIn() ? (
            <DashboardLayout />
          ) : (
            <Navigate to="/login" state={{ pathname }} />
          ),
          children: [
            { path: '/', element: <Navigate to="/dashboard" /> },
            { path: 'account', element: <AccountView /> },
            { path: 'users', element: <UserListView /> },
            // { path: 'products', element: <ProductListView /> },
            { path: 'profile', element: <ProfileListView /> },
            { path: 'dashboard', element: <DashboardView /> },
            { path: 'settings', element: <SettingsView /> },
            {
              path: 'introduction',
              children: [
                {
                  path: '/',
                  element: <IntroductionView />,
                },
                {
                  path: '/:introductionId',
                  element: <Introduction />,
                },
                {
                  path: '/:introductionId/edit',
                  element: <EditIntroduction />,
                },
                {
                  path: '/add',
                  element: <EditIntroduction />,
                },
              ],
            },

            {
              path: 'whyus',
              children: [
                {
                  path: '/',
                  element: <WhyUsView />,
                },
                {
                  path: '/:whyUsId',
                  element: <Whyus />,
                },
                {
                  path: '/:whyUsId/edit',
                  element: <EditWhyUs />,
                },
                {
                  path: '/add',
                  element: <EditWhyUs />,
                },
              ],
            },

            {
              path: 'site-setting',
              children: [
                {
                  path: '/',
                  element: <SiteSettingView />,
                },
                {
                  path: '/view/:settingId',
                  element: <SiteSetting />,
                },
                {
                  path: '/edit/:settingId',
                  element: <EditSiteSetting />,
                },
                {
                  path: '/add',
                  element: <EditSiteSetting />,
                },
              ],
            },

            {
              path: 'profilebanner',
              children: [
                {
                  path: '/',
                  element: <ProfileBannerView />,
                },
                {
                  path: '/:profileBannerId',
                  element: <ProfileBanner />,
                },
                {
                  path: '/:profileBannerId/edit',
                  element: <EditProfileBanner />,
                },
                {
                  path: '/add',
                  element: <EditProfileBanner />,
                },
              ],
            },

            {
              path: 'timeline',
              children: [
                {
                  path: '/',
                  element: <TimelineView />,
                },
                {
                  path: '/:timelineId',
                  element: <Timeline />,
                },
                {
                  path: '/:timelineId/edit',
                  element: <EditTimeline />,
                },
                {
                  path: '/add',
                  element: <EditTimeline />,
                },
              ],
            },

            {
              path: 'certificate',
              children: [
                {
                  path: '/',
                  element: <CertificateView />,
                },
                {
                  path: '/:certificateId',
                  element: <Certificate />,
                },
                {
                  path: '/:certificateId/edit',
                  element: <EditCertificate />,
                },
                {
                  path: '/add',
                  element: <EditCertificate />,
                },
              ],
            },

            {
              path: 'productcategory',
              children: [
                {
                  path: '/',
                  element: <ProductCategoryView />,
                },
                {
                  path: '/:productcategory/edit',
                  element: <EditProductCategory />,
                },
                {
                  path: '/add',
                  element: <EditProductCategory />,
                },
              ],
            },

            {
              path: 'products',
              children: [
                {
                  path: '/',
                  element: <ProductsView />,
                },
                {
                  path: ':productId',
                  element: <Product />,
                },
                {
                  path: '/:productId/edit',
                  element: <EditProduct />,
                },
                {
                  path: '/add',
                  element: <EditProduct />,
                },
              ],
            },

            {
              path: 'contact-us',
              children: [
                {
                  path: '/',
                  element: <ContactView />,
                },
                {
                  path: ':contactId',
                  element: <Contact />,
                },
              ],
            },

            { path: '*', element: <NotFoundView /> },
          ],
        },
        { path: '*', element: <NotFoundView /> },
      ],
    },
    // {
    //   path: '/',
    //   element: isLoggedIn() ? <DashboardLayout /> : <Navigate to="/login" />,
    //   children: [
    //     { path: 'account', element: <AccountView /> },
    //     { path: 'customers', element: <UserListView /> },
    //     { path: 'dashboard', element: <DashboardView /> },
    //     { path: 'products', element: <ProductListView /> },
    //     { path: 'settings', element: <SettingsView /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
  ];
  return routes;
};

// export default routes;
