export const ADD_PRODUCT = 'ADD_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_ALL_PRODUCTS = 'ADDFETCH_ALL_PRODUCTS_PRODUCT';
export const FETCH_PRODUCTS_BY_CATEGORY = 'FETCH_PRODUCTS_BY_CATEGORY';

export function addProduct(payload) {
  return { type: ADD_PRODUCT, payload };
}
export function updateProduct(payload) {
  return { type: UPDATE_PRODUCT, payload };
}
export function deleteProduct(payload) {
  return { type: DELETE_PRODUCT, payload };
}
export function fetchProduct(payload) {
  return { type: FETCH_PRODUCT, payload };
}
export function fetchAllProducts(payload) {
  return { type: FETCH_ALL_PRODUCTS, payload };
}
export function fetchProductsByCategory(payload) {
  return { type: FETCH_PRODUCTS_BY_CATEGORY, payload };
}
