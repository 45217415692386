import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  User as UserIcon,
  Users as UsersIcon,
  Archive as ProductIcon,
} from 'react-feather';
import NavItem from './NavItem';
import InputIcon from '@material-ui/icons/Input';

import userService from 'src/services/userService';
import { useDispatch, useSelector } from 'react-redux';
import { getImageUrl, isLoggedIn } from 'src/utils/helper';
import Navigation from 'src/components/Navigation';

const items = [
  {
    title: 'Main',
    pages: [
      {
        href: '/dashboard',
        icon: BarChartIcon,
        title: 'Dashboard',
      },
      {
        href: '/site-setting',
        icon: SettingsIcon,
        title: 'Site Setting',
      },
      // {
      //   href: '/introduction',
      //   icon: BarChartIcon,
      //   title: 'Introduction',
      // },
      // {
      //   href: '/whyus',
      //   icon: BarChartIcon,
      //   title: 'Why Us',
      // },
      // {
      //   href: '/profilebanner',
      //   icon: BarChartIcon,
      //   title: 'Profile Banner',
      // },
      // {
      //   href: '/timeline',
      //   icon: BarChartIcon,
      //   title: 'Timeline',
      // },
      // {
      //   href: '/certificate',
      //   icon: BarChartIcon,
      //   title: 'Certificate',
      // },
      // {
      //   href: '/contact-us',
      //   icon: UserIcon,
      //   title: 'Contact Us',
      // },
      {
        href: '/products',
        icon: ProductIcon,
        title: 'Products',
        children: [
          {
            href: '/productcategory',
            icon: UsersIcon,
            title: 'Category',
          },
          {
            href: '/products',
            icon: UsersIcon,
            title: 'Products',
          },
        ],
      },
      {
        href: '/users',
        icon: UsersIcon,
        title: 'Users',
      },
    ],
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.profile);
  useEffect(() => {
    async function getUser() {
      try {
        await userService.profile(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    if (isLoggedIn() && !user?.name) {
      getUser();
    }
  }, []);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={getImageUrl(user.avatar)}
          to="/account"
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.role}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        {/* <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List> */}
        <nav>
          {items.map((list) => (
            <Navigation
              component="div"
              key={list.title}
              pages={list.pages}
              title={list.title}
            />
          ))}
        </nav>
      </Box>
      <Box flexGrow={1} />
      <Hidden lgUp>
        <Box p={2}>
          <List
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              userService.logout(dispatch);
            }}
          >
            <NavItem href="/logout" title="Logout" icon={InputIcon} />
          </List>
        </Box>
      </Hidden>
    </Box>
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
