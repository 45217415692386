import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  InputLabel,
  Breadcrumbs,
  MenuItem,
  Typography,
  Avatar,
  CircularProgress,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik, FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import adminService from '../../services/adminService';
import { consoleError, getImageUrl } from '../../utils/helper';
import * as yup from 'yup';
import { TextField, Switch } from 'formik-material-ui';
import FormPreviewDev from '../../components/common/FormPreviewDev';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useMatch, useNavigate, Link } from 'react-router-dom';
import CustomFileUpload from '../../components/CustomFileUpload';
import CustomFileUploadServer from '../../components/CustomFileUploadServer';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import api from 'src/services/api';
import config from '../../config';
// import FileUploadServer from '../../components/FileUploadServer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropDown: {
    height: 400,
  },
}));
function EditProductCategory() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [id, setId] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);

  const match = useMatch('/productcategory/:id/edit');
  useEffect(() => {
    if (match && match.params && match.params.id) {
      setId(match.params.id);
      setUpdate(true);
    }
  }, [match]);

  const data = useSelector((state) => state.admin.productcategory);
  const productCategoryData = data.find((Item) => Item._id === id);

  console.log(productCategoryData);
  useEffect(() => {
    getProductCategory();
  }, []);

  const getProductCategory = async () => {
    try {
      adminService.getProductCategory(dispatch);
    } catch (error) {
      console.error(error);
    }
  };

  const addSiteSettingchema = yup.object().shape({
    category: yup
      .string()
      .required('category is required.')
      .min(2, 'category has min 2 chars.')
      .max(50, 'category has max 50 chars.'),
  });
  const updateSiteSettingchema = yup.object().shape({
    category: yup
      .string()
      .required('category is required.')
      .min(2, 'category has min 2 chars.')
      .max(50, 'category has max 50 chars.'),
  });

  // const removeBanner = async (id, imgUrl) => {
  //   // console.log(id);
  //   const res = await api.post(`/api/v1/admin/portfolio/image/${id}`, {
  //     url: imgUrl,
  //   });
  //   if (res && res.ok) {
  //     console.log('Banner Deteted Successfully');
  //     getBanners();
  //   }
  // };

  return (
    <Page
      className={classes.root}
      title={update ? 'Update Category' : 'Add Category'}
    >
      <Box
        display="flex"
        flexdirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h2">
              {update ? 'Update category' : 'Add category'}
            </Typography>
            <Box ml={2}>
              {/* <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate('/productcategory/add');
                }}
              >
                Add New Category
              </Button> */}
            </Box>
          </Box>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Link color="grey" to="/productcategory">
                Product Category
              </Link>
              <Typography color="textPrimary">
                {update ? 'Update' : 'Add'}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Formik
            enableReinitialize={true}
            initialValues={
              update
                ? {
                    ...productCategoryData,
                    currentCategoryImage:
                      productCategoryData &&
                      productCategoryData.categoryImage &&
                      productCategoryData.categoryImage.length > 0
                        ? productCategoryData.categoryImage[0].image
                        : '',
                  }
                : {
                    category: '',
                    categoryImage: '',
                  }
            }
            validationSchema={
              update ? updateSiteSettingchema : addSiteSettingchema
            }
            onSubmit={async (values, { resetForm }) => {
              setErrMsg('');
              setSuccessMsg('');
              const { _id, category, categoryImage } = values;
              console.log('image', categoryImage);
              if (!categoryImage) {
                setErrMsg('Please upload an image first');
                return;
              }
              const data = {
                category,
                categoryImage,
              };
              const formData = new FormData();
              for (const key in data) {
                formData.append(key, data[key]);
              }
              if (update) {
                try {
                  let res = await adminService.updateProductCategory(
                    _id,
                    formData,
                    dispatch,
                  );
                  if (res && res.ok) {
                    setSuccessMsg(
                      res.message || 'ProductCategory updated successfully.',
                    );
                    setTimeout(() => {
                      window.location.reload();
                    }, 500);
                  }
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message ||
                      'Error updating ProductCategory. Please try again',
                  );
                }
              } else {
                try {
                  let res = await adminService.addProductCategory(
                    formData,
                    dispatch,
                  );
                  if (res && res.ok) {
                    setSuccessMsg(
                      res.message || 'ProductCategory added successfully.',
                    );
                    resetForm({});
                  }
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message ||
                      'Error adding ProductCategory. Please try again.',
                  );
                }
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Category"
                      margin="normal"
                      size="medium"
                      name="category"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={CustomFileUpload}
                      name="categoryImage"
                      label="Category Image"
                    />

                    <br />

                    {values.currentCategoryImage || values.categoryImage ? (
                      <img
                        src={`${config.apiUrl}${config.imageUrlLocal}/categories/${values.currentCategoryImage}`}
                        alt="Service"
                        style={{ width: '200px', height: '200px' }}
                      />
                    ) : (
                      ''
                    )}

                    {/* {values.categoryImage || values.currentCategoryImage ? (
                      <Avatar
                        alt="categroy"
                        src={
                          update && !values.categoryImage
                            ? `${config.apiUrl}${config.imageUrlLocal}/uploads/categories/${values.currentCategoryImage}`
                            : `${config.apiUrl}${config.imageUrlLocal}/uploads/categories/${values.currentCategoryImage}`
                        }
                        variant="square"
                        className={classes.avatar}
                      />
                    ) : null} */}
                  </Grid>
                </Grid>
                <Box mt={1} mb={1}>
                  {errMsg && (
                    <Alert severity="error" onClose={() => setErrMsg('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errMsg}
                    </Alert>
                  )}
                  {successMsg && (
                    <Alert severity="success" onClose={() => setSuccessMsg('')}>
                      <AlertTitle>Success</AlertTitle>
                      {successMsg}
                    </Alert>
                  )}
                </Box>
                {(isSubmitting || uploadingImage) && <LinearProgress />}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting || uploadingImage}
                    fullWidth
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    {update ? 'Update Category' : 'Add Category'}
                  </Button>
                </Box>
                <FormPreviewDev data={values} />
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
}

export default EditProductCategory;
