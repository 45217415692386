import api from './api';

import {
  adminGetUsers,
  adminAddUser,
  adminUpdateUser,
  adminDeleteUser,
  adminGetProductCategory,
  adminAddProductCategory,
  adminUpdateProductCategory,
  adminDeleteProductCategory,
  adminGetProduct,
  adminAddProduct,
  adminUpdateProduct,
  adminDeleteProduct,
  adminGetIntroduction,
  adminAddIntroduction,
  adminUpdateIntroduction,
  adminDeleteIntroduction,
  adminGetWhyUs,
  adminAddWhyUs,
  adminUpdateWhyUs,
  adminDeleteWhyUs,
  adminGetSiteSetting,
  adminUpdateSiteSetting,
  adminGetProfileBanner,
  adminAddProfileBanner,
  adminUpdateProfileBanner,
  adminDeleteProfileBanner,
  adminGetTimeline,
  adminAddTimeline,
  adminUpdateTimeline,
  adminDeleteTimeline,
  adminGetCertificate,
  adminAddCertificate,
  adminUpdateCertificate,
  adminDeleteCertificate,
  adminGetContactusall,
  adminDeleteContactus,
} from '../store/actions/adminActions';

const adminService = {
  // USER
  getUsers: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/users');
      dispatch(adminGetUsers(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addUser: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/users', data, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      dispatch(adminAddUser(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateUser: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/users/${_id}`, data, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      dispatch(adminUpdateUser(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteUser: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/users/${_id}`);
      dispatch(adminDeleteUser(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getTranslation: async (text) => {
    try {
      let res = await api.post('/api/v1/admin/translate/chinese', {
        text: text,
      });
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // Preoduct Category
  getProductCategory: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/categories');
      dispatch(adminGetProductCategory(res.data.categories));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addProductCategory: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/category', data);
      dispatch(adminAddProductCategory(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateProductCategory: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/category/${_id}`, data);
      dispatch(adminUpdateProductCategory(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteProductCategory: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/category/${_id}`);
      dispatch(adminDeleteProductCategory(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // Product
  getProducts: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/products');
      dispatch(adminGetProduct(res.data.products));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addProduct: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/addProduct', data);
      dispatch(adminAddProduct(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateProduct: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/product/${_id}`, data);
      dispatch(adminUpdateProduct(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteProduct: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/product/${_id}`);
      dispatch(adminDeleteProduct(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // Introduction
  getIntroduction: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/introduction');
      dispatch(adminGetIntroduction(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addIntroduction: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/introduction/store', data);
      dispatch(adminAddIntroduction(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateIntroduction: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(
        `/api/v1/admin/introduction/update/${_id}`,
        data,
      );
      dispatch(adminUpdateIntroduction(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteIntroduction: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/introduction/delete/${_id}`);
      dispatch(adminDeleteIntroduction(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // WhyUs
  getWhyUs: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/whyus');
      dispatch(adminGetWhyUs(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addWhyUs: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/whyus/store', data);
      dispatch(adminAddWhyUs(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateWhyUs: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/whyus/update/${_id}`, data);
      dispatch(adminUpdateWhyUs(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteWhyUs: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/whyus/delete/${_id}`);
      dispatch(adminDeleteWhyUs(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // Site Setting
  getSiteSetting: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/setting');
      dispatch(adminGetSiteSetting(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateSiteSetting: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/setting/update/${_id}`, data);
      dispatch(adminUpdateSiteSetting(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // Profile Banner
  getProfileBanner: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/profileBanner');
      dispatch(adminGetProfileBanner(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addProfileBanner: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/profileBanner/store', data);
      dispatch(adminAddProfileBanner(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateProfileBanner: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(
        `/api/v1/admin/profileBanner/update/${_id}`,
        data,
      );
      dispatch(adminUpdateProfileBanner(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteProfileBanner: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/profileBanner/delete/${_id}`);
      dispatch(adminDeleteProfileBanner(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // Timeline
  getTimeline: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/timeline');
      dispatch(adminGetTimeline(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addTimeline: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/timeline/store', data);
      dispatch(adminAddTimeline(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateTimeline: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/timeline/update/${_id}`, data);
      dispatch(adminUpdateTimeline(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteTimeline: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/timeline/delete/${_id}`);
      dispatch(adminDeleteTimeline(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // Certificate
  getCertificate: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/certificate');
      dispatch(adminGetCertificate(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addCertificate: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/certificate/store', data);
      dispatch(adminAddCertificate(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateCertificate: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(
        `/api/v1/admin/certificate/update/${_id}`,
        data,
      );
      dispatch(adminUpdateCertificate(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteCertificate: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/certificate/delete/${_id}`);
      dispatch(adminDeleteCertificate(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  //Contact us
  getContactusall: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/contacts');
      dispatch(adminGetContactusall(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteContactus: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/contact/delete/${_id}`);
      dispatch(adminDeleteContactus(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default adminService;
