import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import adminService from 'src/services/adminService';
import categoryService from 'src/services/categoryService';
import productService from 'src/services/productService';
import { consoleError, getImageUrl } from 'src/utils/helper';
import * as yup from 'yup';
import { TextField, Switch } from 'formik-material-ui';
import FormPreviewDev from 'src/components/common/FormPreviewDev';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import CustomFileUpload from 'src/components/CustomFileUpload';
import categoryList from 'src/components/categoryList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function AddProduct({ product = null, category = null }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.category.categories);

  const [update, setUpdate] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [allCategory, setallCategory] = useState('');

  const firstLoad = useRef(true);

  useEffect(() => {
    if (product) {
      setUpdate(true);
    }
  }, [product]);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      categoryService.fetchAllCategory(dispatch);
      return;
    }
    setallCategory(categories);
    return;
  }, []);

  const addProductSchema = yup.object().shape({
    productType: yup.string().required('Product Type is Required.'),
    modelNo: yup.string().required('Model is required.'),
    upper: yup.string().required('Upper is required.'),
    vampLining: yup.string().required('vampLining is required.'),
    quaterLining: yup.string().required('quaterLining is required.'),
    outsole: yup.string().required('outsole is required.'),
    sizeRange: yup.string().required('sizeRange is required.'),
    safetyShoesStandard: yup
      .string()
      .required('safetyShoesStandard is required.'),
    remarks: yup.string().required('remarks is required.'),
    category: yup.string().required('category is required.'),
    productImage: yup
      .mixed()
      .test('fileType', 'Please select image (jpeg/png) file only.', (value) =>
        value ? SUPPORTED_FORMATS.includes(value ? value.type : null) : true,
      ),
  });
  const updateProductSchema = yup.object().shape({
    productType: yup.string().required('Product Type is Required.'),
    modelNo: yup.string().required('Model is required.'),
    upper: yup.string().required('Upper is required.'),
    vampLining: yup.string().required('vampLining is required.'),
    quaterLining: yup.string().required('quaterLining is required.'),
    outsole: yup.string().required('outsole is required.'),
    sizeRange: yup.string().required('sizeRange is required.'),
    safetyShoesStandard: yup
      .string()
      .required('safetyShoesStandard is required.'),
    remarks: yup.string().required('remarks is required.'),
    category: yup.string().required('category is required.'),
    productImage: yup
      .mixed()
      .test('fileType', 'Please select image (jpeg/png) file only.', (value) =>
        value ? SUPPORTED_FORMATS.includes(value ? value.type : null) : true,
      ),
  });
  const SUPPORTED_FORMATS = ['image/jpeg', 'image/png'];

  return (
    <Page
      className={classes.root}
      title={update ? 'Update Product' : 'Add Product'}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            enableReinitialize={true}
            initialValues={
              update
                ? {
                    _id: product._id,
                    productType: product.productType,
                    colors: product.colors,
                    modelNo: product.modelNo,
                    upper: product.upper,
                    vampLining: product.vampLining,
                    quaterLining: product.quaterLining,
                    outsole: product.outsole,
                    sizeRange: product.sizeRange,
                    safetyShoesStandard: product.safetyShoesStandard,
                    remarks: product.remarks,
                    category: product.category,
                    productImage: '',
                  }
                : {
                    _id: '',
                    productType: '',
                    colors: '',
                    modelNo: '',
                    upper: '',
                    vampLining: '',
                    quaterLining: '',
                    outsole: '',
                    sizeRange: '',
                    safetyShoesStandard: '',
                    remarks: '',
                    category: '',
                    productImage: '',
                  }
            }
            validationSchema={update ? updateProductSchema : addProductSchema}
            onSubmit={async (values, { resetForm }) => {
              setErrMsg('');
              setSuccessMsg('');
              const {
                _id,
                productType,
                colors,
                modelNo,
                upper,
                vampLining,
                quaterLining,
                outsole,
                sizeRange,
                safetyShoesStandard,
                remarks,
                category,
                productImage,
              } = values;
              const data = {
                productType,
                colors,
                modelNo,
                upper,
                vampLining,
                quaterLining,
                outsole,
                sizeRange,
                safetyShoesStandard,
                remarks,
                category,
                productImage,
              };
              const formData = new FormData();
              for (const key in data) {
                formData.append(key, data[key]);
              }
              if (update) {
                if (values.productImage) {
                  formData.append('productImage', values.productImage);
                }
              } else {
                try {
                  //   data['password'] = password;
                  // formData.append('productImage', productImage);
                  let res = await productService.addProduct(formData, dispatch);
                  if (res && res.ok) {
                    setSuccessMsg(res.message || 'User added successfully.');
                    resetForm({});
                  }
                  //
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message || 'Error adding user. Please try again.',
                  );
                }
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <Field
                        component={TextField}
                        name="category"
                        type="text"
                        label="category"
                        select={true}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setFieldValue(name, value);
                        }}
                        variant="outlined"
                        fullWidth
                      >
                        {categories.map((categories, index) => (
                          <MenuItem key={index} value={categories._id}>
                            {categories.category}
                          </MenuItem>
                        ))}
                      </Field>
                      {/* <ErrorMessage name="type" /> */}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <Field
                        component={TextField}
                        name="productType"
                        type="text"
                        label="Product Type"
                        select={true}
                        variant="outlined"
                        fullWidth
                      >
                        <MenuItem value={'White Safety Shoes'}>
                          White Safety Shoes
                        </MenuItem>
                        <MenuItem value={'Stylish Safety Shoes'}>
                          Stylish Safety Shoes
                        </MenuItem>
                        <MenuItem value={'Basic Safety Shoes'}>
                          Basic Safety Shoes
                        </MenuItem>
                        <MenuItem value={'Safety Boots'}>Safety Boots</MenuItem>
                      </Field>
                      {/* <ErrorMessage name="type" /> */}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Model"
                      margin="normal"
                      size="medium"
                      name="modelNo"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Upper"
                      margin="normal"
                      size="medium"
                      name="upper"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Vamp Lining"
                      margin="normal"
                      size="medium"
                      name="vampLining"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Quarter Lining"
                      margin="normal"
                      size="medium"
                      name="quaterLining"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Outsole"
                      margin="normal"
                      size="medium"
                      name="outsole"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Size Range"
                      margin="normal"
                      size="medium"
                      name="sizeRange"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Lead Time"
                      margin="normal"
                      size="medium"
                      name="leadTime"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Safety Shoes Standard"
                      margin="normal"
                      size="medium"
                      name="safetyShoesStandard"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Remarks"
                      margin="normal"
                      size="medium"
                      name="remarks"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Colors"
                      margin="normal"
                      size="medium"
                      name="colors"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={CustomFileUpload}
                      name="productImage"
                      label="Product Image"
                    />
                    {values.productImage || values.productImage ? (
                      <Avatar
                        src={
                          update && !values.productImage
                            ? getImageUrl(values.productImage)
                            : URL.createObjectURL(values.productImage)
                        }
                        variant="square"
                        className={classes.avatar}
                      />
                    ) : null}
                  </Grid>
                </Grid>
                <Box mt={1} mb={1}>
                  {errMsg && (
                    <Alert severity="error" onClose={() => setErrMsg('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errMsg}
                    </Alert>
                  )}
                  {successMsg && (
                    <Alert severity="success" onClose={() => setSuccessMsg('')}>
                      <AlertTitle>Success</AlertTitle>
                      {successMsg}
                    </Alert>
                  )}
                </Box>
                {isSubmitting && <LinearProgress />}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    {update ? 'Update Product' : 'Add Product'}
                  </Button>
                </Box>
                <FormPreviewDev data={values} />
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
}

export default AddProduct;
