// USERS
export const ADMIN_GET_USERS = 'ADMIN_GET_USERS';
export const ADMIN_ADD_USER = 'ADMIN_ADD_USER';
export const ADMIN_UPDATE_USER = 'ADMIN_UPDATE_USER';
export const ADMIN_DELETE_USER = 'ADMIN_DELETE_USER';

export function adminGetUsers(payload) {
  return { type: ADMIN_GET_USERS, payload };
}
export function adminAddUser(payload) {
  return { type: ADMIN_ADD_USER, payload };
}
export function adminUpdateUser(payload) {
  return { type: ADMIN_UPDATE_USER, payload };
}
export function adminDeleteUser(payload) {
  return { type: ADMIN_DELETE_USER, payload };
}

// PRODUCT CATEGORY
export const ADMIN_GET_PRODUCT_CATEGORY = 'ADMIN_GET_PRODUCT_CATEGORY';
export const ADMIN_ADD_PRODUCT_CATEGORY = 'ADMIN_ADD_PRODUCT_CATEGORY';
export const ADMIN_UPDATE_PRODUCT_CATEGORY = 'ADMIN_UPDATE_PRODUCT_CATEGORY';
export const ADMIN_DELETE_PRODUCT_CATEGORY = 'ADMIN_DELETE_PRODUCT_CATEGORY';

export function adminGetProductCategory(payload) {
  return { type: ADMIN_GET_PRODUCT_CATEGORY, payload };
}
export function adminAddProductCategory(payload) {
  return { type: ADMIN_ADD_PRODUCT_CATEGORY, payload };
}
export function adminUpdateProductCategory(payload) {
  return { type: ADMIN_UPDATE_PRODUCT_CATEGORY, payload };
}
export function adminDeleteProductCategory(payload) {
  return { type: ADMIN_DELETE_PRODUCT_CATEGORY, payload };
}

// PRODUCTS
export const ADMIN_GET_PRODUCT = 'ADMIN_GET_PRODUCT';
export const ADMIN_ADD_PRODUCT = 'ADMIN_ADD_PRODUCT';
export const ADMIN_UPDATE_PRODUCT = 'ADMIN_UPDATE_PRODUCT';
export const ADMIN_DELETE_PRODUCT = 'ADMIN_DELETE_PRODUCT';

export function adminGetProduct(payload) {
  return { type: ADMIN_GET_PRODUCT, payload };
}
export function adminAddProduct(payload) {
  return { type: ADMIN_ADD_PRODUCT, payload };
}
export function adminUpdateProduct(payload) {
  return { type: ADMIN_UPDATE_PRODUCT, payload };
}
export function adminDeleteProduct(payload) {
  return { type: ADMIN_DELETE_PRODUCT, payload };
}

// INTRODUCTION
export const ADMIN_GET_INTRODUCTION = 'ADMIN_GET_INTRODUCTION';
export const ADMIN_ADD_INTRODUCTION = 'ADMIN_ADD_INTRODUCTION';
export const ADMIN_UPDATE_INTRODUCTION = 'ADMIN_UPDATE_INTRODUCTION';
export const ADMIN_DELETE_INTRODUCTION = 'ADMIN_DELETE_INTRODUCTION';

export function adminGetIntroduction(payload) {
  return { type: ADMIN_GET_INTRODUCTION, payload };
}
export function adminAddIntroduction(payload) {
  return { type: ADMIN_ADD_INTRODUCTION, payload };
}
export function adminUpdateIntroduction(payload) {
  return { type: ADMIN_UPDATE_INTRODUCTION, payload };
}
export function adminDeleteIntroduction(payload) {
  return { type: ADMIN_DELETE_INTRODUCTION, payload };
}

// WHYUS
export const ADMIN_GET_WHYUS = 'ADMIN_GET_WHYUS';
export const ADMIN_ADD_WHYUS = 'ADMIN_ADD_WHYUS';
export const ADMIN_UPDATE_WHYUS = 'ADMIN_UPDATE_WHYUS';
export const ADMIN_DELETE_WHYUS = 'ADMIN_DELETE_WHYUS';

export function adminGetWhyUs(payload) {
  return { type: ADMIN_GET_WHYUS, payload };
}
export function adminAddWhyUs(payload) {
  return { type: ADMIN_ADD_WHYUS, payload };
}
export function adminUpdateWhyUs(payload) {
  return { type: ADMIN_UPDATE_WHYUS, payload };
}
export function adminDeleteWhyUs(payload) {
  return { type: ADMIN_DELETE_WHYUS, payload };
}

// SITE SETTING
export const ADMIN_GET_SITE_SETTING = 'ADMIN_GET_SITE_SETTING';
export const ADMIN_UPDATE_SITE_SETTING = 'ADMIN_UPDATE_SITE_SETTING';

export function adminGetSiteSetting(payload) {
  return { type: ADMIN_GET_SITE_SETTING, payload };
}
export function adminUpdateSiteSetting(payload) {
  return { type: ADMIN_UPDATE_SITE_SETTING, payload };
}

// Profile Banner
export const ADMIN_GET_PROFILE_BANNER = 'ADMIN_GET_PROFILE_BANNER';
export const ADMIN_ADD_PROFILE_BANNER = 'ADMIN_ADD_PROFILE_BANNER';
export const ADMIN_UPDATE_PROFILE_BANNER = 'ADMIN_UPDATE_PROFILE_BANNER';
export const ADMIN_DELETE_PROFILE_BANNER = 'ADMIN_DELETE_PROFILE_BANNER';

export function adminGetProfileBanner(payload) {
  return { type: ADMIN_GET_PROFILE_BANNER, payload };
}
export function adminAddProfileBanner(payload) {
  return { type: ADMIN_ADD_PROFILE_BANNER, payload };
}
export function adminUpdateProfileBanner(payload) {
  return { type: ADMIN_UPDATE_PROFILE_BANNER, payload };
}
export function adminDeleteProfileBanner(payload) {
  return { type: ADMIN_DELETE_PROFILE_BANNER, payload };
}

// Timeline
export const ADMIN_GET_TIMELINE = 'ADMIN_GET_TIMELINE';
export const ADMIN_ADD_TIMELINE = 'ADMIN_ADD_TIMELINE';
export const ADMIN_UPDATE_TIMELINE = 'ADMIN_UPDATE_TIMELINE';
export const ADMIN_DELETE_TIMELINE = 'ADMIN_DELETE_TIMELINE';

export function adminGetTimeline(payload) {
  return { type: ADMIN_GET_TIMELINE, payload };
}
export function adminAddTimeline(payload) {
  return { type: ADMIN_ADD_TIMELINE, payload };
}
export function adminUpdateTimeline(payload) {
  return { type: ADMIN_UPDATE_TIMELINE, payload };
}
export function adminDeleteTimeline(payload) {
  return { type: ADMIN_DELETE_TIMELINE, payload };
}

// Certificate
export const ADMIN_GET_CERTIFICATE = 'ADMIN_GET_CERTIFICATE';
export const ADMIN_ADD_CERTIFICATE = 'ADMIN_ADD_CERTIFICATE';
export const ADMIN_UPDATE_CERTIFICATE = 'ADMIN_UPDATE_CERTIFICATE';
export const ADMIN_DELETE_CERTIFICATE = 'ADMIN_DELETE_CERTIFICATE';

export function adminGetCertificate(payload) {
  return { type: ADMIN_GET_CERTIFICATE, payload };
}
export function adminAddCertificate(payload) {
  return { type: ADMIN_ADD_CERTIFICATE, payload };
}
export function adminUpdateCertificate(payload) {
  return { type: ADMIN_UPDATE_CERTIFICATE, payload };
}
export function adminDeleteCertificate(payload) {
  return { type: ADMIN_DELETE_CERTIFICATE, payload };
}

//Contact us
export const ADMIN_GET_CONTACTUSALL = 'ADMIN_GET_CONTACTUSALL';
export const ADMIN_DELETE_CONTACTUS = 'ADMIN_DELETE_CONTACTUS';

export function adminGetContactusall(payload) {
  return { type: ADMIN_GET_CONTACTUSALL, payload };
}
export function adminDeleteContactus(payload) {
  return { type: ADMIN_DELETE_CONTACTUS, payload };
}
