import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  InputLabel,
  Breadcrumbs,
  MenuItem,
  Typography,
  Avatar,
  CircularProgress,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik, FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import adminService from '../../services/adminService';
import { consoleError, getImageUrl } from '../../utils/helper';
import * as yup from 'yup';
import { TextField, Switch } from 'formik-material-ui';
import FormPreviewDev from '../../components/common/FormPreviewDev';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useMatch, useNavigate, Link } from 'react-router-dom';
import CustomFileUpload from '../../components/CustomFileUpload';
import CustomFileUploadServer from '../../components/CustomFileUploadServer';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import api from 'src/services/api';
// import FileUploadServer from '../../components/FileUploadServer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropDown: {
    height: 400,
  },
}));
function EditWhyUs() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [id, setId] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);

  const match = useMatch('/whyus/:id/edit');
  useEffect(() => {
    if (match && match.params && match.params.id) {
      setId(match.params.id);
      setUpdate(true);
    }
  }, [match]);

  const whyus = useSelector((state) => state.admin.whyus);
  const introductionData = whyus.find((Item) => Item._id.toString() === id);

  useEffect(() => {
    getWhyUs();
  }, []);

  const getWhyUs = async () => {
    try {
      adminService.getWhyUs(dispatch);
    } catch (error) {
      console.error(error);
    }
  };

  const addSiteSettingchema = yup.object().shape({
    title: yup
      .string()
      .required('title is required.')
      .min(2, 'title has min 2 chars.')
      .max(200, 'title has max 200 chars.'),
  });
  const updateSiteSettingchema = yup.object().shape({
    title: yup
      .string()
      .required('title is required.')
      .min(2, 'title has min 2 chars.')
      .max(200, 'title has max 200 chars.'),
  });

  return (
    <Page className={classes.root} title={update ? 'Update' : 'Add'}>
      <Box
        display="flex"
        flexdirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h2">{update ? 'Update' : 'Add'}</Typography>
            <Box ml={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  navigate('/whyus/add');
                }}
              >
                Add New
              </Button>
            </Box>
          </Box>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Link color="grey" to="/whyus">
                Why Us
              </Link>
              <Typography color="textPrimary">
                {update ? 'Update' : 'Add'}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Formik
            enableReinitialize={true}
            initialValues={
              update
                ? {
                    ...introductionData,
                  }
                : {
                    title: '',
                    desc: [
                      {
                        stat: '',
                        title: '',
                      },
                    ],
                  }
            }
            validationSchema={
              update ? updateSiteSettingchema : addSiteSettingchema
            }
            onSubmit={async (values, { resetForm }) => {
              setErrMsg('');
              setSuccessMsg('');
              const { _id, title, desc } = values;
              const data = {
                title,
                desc,
              };
              if (update) {
                try {
                  let res = await adminService.updateWhyUs(_id, data, dispatch);
                  if (res && res.ok) {
                    setSuccessMsg(res.message || 'WhyUs updated successfully.');
                    setTimeout(() => {
                      window.location.reload();
                    }, 500);
                  }
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message || 'Error updating WhyUs. Please try again',
                  );
                }
              } else {
                try {
                  let res = await adminService.addWhyUs(data, dispatch);
                  if (res && res.ok) {
                    setSuccessMsg(res.message || 'WhyUs added successfully.');
                    resetForm({});
                  }
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message || 'Error adding WhyUs. Please try again.',
                  );
                }
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Title"
                      margin="normal"
                      size="medium"
                      name="title"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box mb={2}>
                      <Typography variant="h6">Description</Typography>
                    </Box>
                    <FieldArray name="desc">
                      {({ remove, push }) => (
                        <Box>
                          {values.desc && values.desc.length > 0 ? (
                            values.desc.map((desc, index) => (
                              <Grid container key={index} spacing={2}>
                                <Grid item xs={5} md={4}>
                                  <Field
                                    component={TextField}
                                    label="Stats"
                                    size="medium"
                                    name={`desc.${index}.stat`}
                                    fullWidth
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={5} md={4}>
                                  <Field
                                    component={TextField}
                                    label="Title"
                                    size="medium"
                                    name={`desc.${index}.title`}
                                    fullWidth
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid
                                  xs={2}
                                  md={4}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => remove(index)}
                                    style={{
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                    }}
                                  >
                                    <RemoveIcon />
                                    Remove
                                  </Button>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() =>
                                      push({
                                        stat: '',
                                        title: '',
                                      })
                                    }
                                  >
                                    <AddIcon />
                                    Add new
                                  </Button>
                                </Grid>
                              </Grid>
                            ))
                          ) : (
                            <Grid container spacing={2}>
                              <Grid item xs={5} md={4}>
                                <Field
                                  component={TextField}
                                  label="Stats"
                                  size="medium"
                                  name={`desc.${
                                    values.desc && values.desc.length
                                  }.stat`}
                                  fullWidth
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={5} md={4}>
                                <Field
                                  component={TextField}
                                  label="Title"
                                  size="medium"
                                  name={`desc.${
                                    values.desc && values.desc.length
                                  }.title`}
                                  fullWidth
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid
                                xs={2}
                                md={4}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() => remove(values.desc.length)}
                                  style={{
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                  }}
                                >
                                  <RemoveIcon />
                                  Remove
                                </Button>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() =>
                                    push({
                                      stat: '',
                                      title: '',
                                    })
                                  }
                                >
                                  <AddIcon />
                                  Add new
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </Box>
                      )}
                    </FieldArray>
                  </Grid>
                </Grid>
                <Box mt={1} mb={1}>
                  {errMsg && (
                    <Alert severity="error" onClose={() => setErrMsg('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errMsg}
                    </Alert>
                  )}
                  {successMsg && (
                    <Alert severity="success" onClose={() => setSuccessMsg('')}>
                      <AlertTitle>Success</AlertTitle>
                      {successMsg}
                    </Alert>
                  )}
                </Box>
                {(isSubmitting || uploadingImage) && <LinearProgress />}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting || uploadingImage}
                    fullWidth
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    {update ? 'Update' : 'Add'}
                  </Button>
                </Box>
                <FormPreviewDev data={values} />
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
}

export default EditWhyUs;
