import { FETCH_CATEGORIES } from '../actions/categoryActions';

const initialState = {
  categories: [],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CATEGORIES:
      return {
        ...state,
        categories: [...action.payload],
      };
    default:
      return state;
  }
}
