// import { Typography } from '@material-ui/core';
import React from 'react';

// eslint-disable-next-line no-unused-vars
const Logo = (props) => {
  return (
    <div style={{ display: 'flex' }}>
      {/* <Typography variant="h1" style={{ color: 'white' }}>
        Domain
      </Typography> */}
      <img alt="Logo" src="/static/domain.png" {...props} height="58px" />
    </div>
  );
};

export default Logo;
