import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
// import UserTable from './UserTable';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const ProductListView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Users">
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          {/* <UserTable /> */}
          Add Product
        </Box>
      </Container>
    </Page>
  );
};

export default ProductListView;
