import {
  ADMIN_ADD_USER,
  ADMIN_DELETE_USER,
  ADMIN_GET_USERS,
  ADMIN_UPDATE_USER,
  ADMIN_GET_PRODUCT_CATEGORY,
  ADMIN_ADD_PRODUCT_CATEGORY,
  ADMIN_UPDATE_PRODUCT_CATEGORY,
  ADMIN_DELETE_PRODUCT_CATEGORY,
  ADMIN_GET_PRODUCT,
  ADMIN_ADD_PRODUCT,
  ADMIN_UPDATE_PRODUCT,
  ADMIN_DELETE_PRODUCT,
  ADMIN_GET_INTRODUCTION,
  ADMIN_ADD_INTRODUCTION,
  ADMIN_UPDATE_INTRODUCTION,
  ADMIN_DELETE_INTRODUCTION,
  ADMIN_GET_WHYUS,
  ADMIN_ADD_WHYUS,
  ADMIN_UPDATE_WHYUS,
  ADMIN_DELETE_WHYUS,
  ADMIN_GET_SITE_SETTING,
  ADMIN_UPDATE_SITE_SETTING,
  ADMIN_GET_PROFILE_BANNER,
  ADMIN_ADD_PROFILE_BANNER,
  ADMIN_UPDATE_PROFILE_BANNER,
  ADMIN_DELETE_PROFILE_BANNER,
  ADMIN_GET_TIMELINE,
  ADMIN_ADD_TIMELINE,
  ADMIN_UPDATE_TIMELINE,
  ADMIN_DELETE_TIMELINE,
  ADMIN_GET_CERTIFICATE,
  ADMIN_ADD_CERTIFICATE,
  ADMIN_UPDATE_CERTIFICATE,
  ADMIN_DELETE_CERTIFICATE,
  ADMIN_GET_CONTACTUSALL,
  ADMIN_DELETE_CONTACTUS,
} from '../actions/adminActions';

const initialState = {
  users: [],

  productcategory: [],
  products: [],
  introduction: [],
  whyus: [],
  siteSettings: [],
  profileBanner: [],
  timeline: [],
  certificate: [],
  contactus: [],
};

export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    // USERS
    case ADMIN_GET_USERS:
      return {
        ...state,
        users: [...action.payload],
      };
    case ADMIN_ADD_USER: {
      const users = [...state.users];
      users.push(action.payload);
      return {
        ...state,
        users: users,
      };
    }
    case ADMIN_UPDATE_USER: {
      const users = [...state.users];
      let index = users.findIndex((item) => item._id === action.payload._id);
      users[index] = action.payload;
      return {
        ...state,
        users: users,
      };
    }
    case ADMIN_DELETE_USER: {
      const users = [...state.users];
      let index = users.findIndex((item) => item._id === action.payload);
      users.splice(index, 1);
      return {
        ...state,
        users: users,
      };
    }

    // Product CATEGORY
    case ADMIN_GET_PRODUCT_CATEGORY:
      // console.log(action.payload);
      return {
        ...state,
        productcategory: [...action.payload],
      };
    case ADMIN_ADD_PRODUCT_CATEGORY: {
      const productcategory = [...state.productcategory];
      productcategory.push(action.payload);
      return {
        ...state,
        productcategory: productcategory,
      };
    }
    case ADMIN_UPDATE_PRODUCT_CATEGORY: {
      const productcategory = [...state.productcategory];
      let index = productcategory.findIndex(
        (item) => item._id === action.payload._id,
      );
      productcategory[index] = action.payload;
      return {
        ...state,
        productcategory: productcategory,
      };
    }
    case ADMIN_DELETE_PRODUCT_CATEGORY: {
      const productcategory = [...state.productcategory];
      let index = productcategory.findIndex(
        (item) => item._id === action.payload,
      );
      productcategory.splice(index, 1);
      return {
        ...state,
        productcategory: productcategory,
      };
    }

    // PRODUCTS
    case ADMIN_GET_PRODUCT:
      // console.log(action.payload);
      return {
        ...state,
        products: [...action.payload],
      };
    case ADMIN_ADD_PRODUCT: {
      const products = [...state.products];
      products.push(action.payload);
      return {
        ...state,
        products: products,
      };
    }
    case ADMIN_UPDATE_PRODUCT: {
      const products = [...state.products];
      let index = products.findIndex((item) => item._id === action.payload._id);
      products[index] = action.payload;
      return {
        ...state,
        products: products,
      };
    }
    case ADMIN_DELETE_PRODUCT: {
      const products = [...state.products];
      let index = products.findIndex((item) => item._id === action.payload);
      products.splice(index, 1);
      return {
        ...state,
        products: products,
      };
    }

    // INTRODUCTION
    case ADMIN_GET_INTRODUCTION:
      // console.log(action.payload);
      return {
        ...state,
        introduction: [...action.payload],
      };
    case ADMIN_ADD_INTRODUCTION: {
      const introduction = [...state.introduction];
      introduction.push(action.payload);
      return {
        ...state,
        introduction: introduction,
      };
    }
    case ADMIN_UPDATE_INTRODUCTION: {
      const introduction = [...state.introduction];
      let index = introduction.findIndex(
        (item) => item._id === action.payload._id,
      );
      introduction[index] = action.payload;
      return {
        ...state,
        introduction: introduction,
      };
    }
    case ADMIN_DELETE_INTRODUCTION: {
      const introduction = [...state.introduction];
      let index = introduction.findIndex((item) => item._id === action.payload);
      introduction.splice(index, 1);
      return {
        ...state,
        introduction: introduction,
      };
    }

    // WHYUS
    case ADMIN_GET_WHYUS:
      // console.log(action.payload);
      return {
        ...state,
        whyus: [...action.payload],
      };
    case ADMIN_ADD_WHYUS: {
      const whyus = [...state.whyus];
      whyus.push(action.payload);
      return {
        ...state,
        whyus: whyus,
      };
    }
    case ADMIN_UPDATE_WHYUS: {
      const whyus = [...state.whyus];
      let index = whyus.findIndex((item) => item._id === action.payload._id);
      whyus[index] = action.payload;
      return {
        ...state,
        whyus: whyus,
      };
    }
    case ADMIN_DELETE_WHYUS: {
      const whyus = [...state.whyus];
      let index = whyus.findIndex((item) => item._id === action.payload);
      whyus.splice(index, 1);
      return {
        ...state,
        whyus: whyus,
      };
    }

    // SITE SETTING
    case ADMIN_GET_SITE_SETTING:
      return {
        ...state,
        siteSettings: [action.payload],
      };
    case ADMIN_UPDATE_SITE_SETTING: {
      const siteSettings = [...state.siteSettings];
      let index = siteSettings.findIndex(
        (item) => item._id === action.payload._id,
      );
      siteSettings[index] = action.payload;
      return {
        ...state,
        siteSettings: siteSettings,
      };
    }

    // PROFILE BANNER
    case ADMIN_GET_PROFILE_BANNER:
      // console.log(action.payload);
      return {
        ...state,
        profileBanner: [...action.payload],
      };
    case ADMIN_ADD_PROFILE_BANNER: {
      const profileBanner = [...state.profileBanner];
      profileBanner.push(action.payload);
      return {
        ...state,
        profileBanner: profileBanner,
      };
    }
    case ADMIN_UPDATE_PROFILE_BANNER: {
      const profileBanner = [...state.profileBanner];
      let index = profileBanner.findIndex(
        (item) => item._id === action.payload._id,
      );
      profileBanner[index] = action.payload;
      return {
        ...state,
        profileBanner: profileBanner,
      };
    }
    case ADMIN_DELETE_PROFILE_BANNER: {
      const profileBanner = [...state.profileBanner];
      let index = profileBanner.findIndex(
        (item) => item._id === action.payload,
      );
      profileBanner.splice(index, 1);
      return {
        ...state,
        profileBanner: profileBanner,
      };
    }

    // TIMELINE
    case ADMIN_GET_TIMELINE:
      // console.log(action.payload);
      return {
        ...state,
        timeline: [...action.payload],
      };
    case ADMIN_ADD_TIMELINE: {
      const timeline = [...state.timeline];
      timeline.push(action.payload);
      return {
        ...state,
        timeline: timeline,
      };
    }
    case ADMIN_UPDATE_TIMELINE: {
      const timeline = [...state.timeline];
      let index = timeline.findIndex((item) => item._id === action.payload._id);
      timeline[index] = action.payload;
      return {
        ...state,
        timeline: timeline,
      };
    }
    case ADMIN_DELETE_TIMELINE: {
      const timeline = [...state.timeline];
      let index = timeline.findIndex((item) => item._id === action.payload);
      timeline.splice(index, 1);
      return {
        ...state,
        timeline: timeline,
      };
    }

    // CERTIFICATE
    case ADMIN_GET_CERTIFICATE:
      // console.log(action.payload);
      return {
        ...state,
        certificate: [...action.payload],
      };
    case ADMIN_ADD_CERTIFICATE: {
      const certificate = [...state.certificate];
      certificate.push(action.payload);
      return {
        ...state,
        certificate: certificate,
      };
    }
    case ADMIN_UPDATE_CERTIFICATE: {
      const certificate = [...state.certificate];
      let index = certificate.findIndex(
        (item) => item._id === action.payload._id,
      );
      certificate[index] = action.payload;
      return {
        ...state,
        certificate: certificate,
      };
    }
    case ADMIN_DELETE_CERTIFICATE: {
      const certificate = [...state.certificate];
      let index = certificate.findIndex((item) => item._id === action.payload);
      certificate.splice(index, 1);
      return {
        ...state,
        certificate: certificate,
      };
    }

    //Contact us
    case ADMIN_GET_CONTACTUSALL:
      return {
        ...state,
        contactus: [...action.payload],
      };
    case ADMIN_DELETE_CONTACTUS: {
      const contactus = [...state.contactus];
      let index = contactus.findIndex((item) => item._id === action.payload);
      contactus.splice(index, 1);
      return {
        ...state,
        contactus: contactus,
      };
    }

    default:
      return state;
  }
}
