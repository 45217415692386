import api from './api';

import { addProduct } from 'src/store/actions/productActions';

const productService = {
  addProduct: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/addProduct', data);
      if (res && res.ok) {
        dispatch(addProduct());
        return Promise.resolve(res);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default productService;
